import { __decorate } from "tslib";
import { Component, Watch, Vue, Prop, Ref, } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm/index';
import { OperateType } from '../../../typings/perm-manage';
import * as Utils from '@/common/utils';
import { PermManageService } from '@/sevices';
let AddRole = class AddRole extends Vue {
    constructor() {
        super(...arguments);
        this.OperateType = OperateType;
        this.visible = false;
        this.pageLoading = true;
        this.isLoaded = false;
        this.roleDetail = {};
        this.confirmLoading = false;
        this.modalTitle = '新建角色';
        this.submiting = false;
        this.form = {
            name: '',
            description: '',
        };
        this.rules = {
            name: [
                { required: true, message: '角色名称不能为空', trigger: 'blur' },
                {
                    validator: (value) => /^[\u4e00-\u9fa5_a-zA-Z0-9\-()]{0,30}$/.test(value),
                    message: '请输入中英文、数字及英文符号”()”、“-”、“_”，最大长度为30个字',
                    trigger: 'blur',
                },
            ],
            description: [
                { required: true, message: '角色描述不能为空', trigger: 'blur' },
            ],
        };
    }
    onFormChange() {
        Utils.trim(this.form);
    }
    getParams() {
        const permData = this.permSet.getParams();
        const params = {
            ...this.form,
            permList: permData.commonPermList,
            appPerms: permData.appPermList,
        };
        if (this.operateType === OperateType.Edit) {
            params.id = this.roleDetail.id;
        }
        return params;
    }
    async save() {
        const isValid = this.cform.validate();
        if (!isValid)
            return;
        const params = this.getParams();
        this.confirmLoading = true;
        const method = this.operateType === OperateType.Add ? PermManageService.addRole
            : PermManageService.updateRole;
        method(params).then((res) => {
            if (res.success) {
                this.$message.success('操作成功');
                this.$emit('reload');
                this.onClose();
            }
        }).finally(() => {
            this.confirmLoading = false;
        });
    }
    /**
     * 获取角色详情
     */
    getRoleDetail() {
        return PermManageService.getRoleDetail({
            id: this.roleId,
        }).then((res) => {
            if (res.success) {
                return res.data;
            }
        });
    }
    onClose() {
        this.$emit('input', false);
    }
    async onValueChange(v) {
        this.visible = v;
        if (!v) {
            this.form.name = '';
            this.form.description = '';
            this.roleDetail = {};
            return;
        }
        ;
        this.modalTitle = (this.operateType === OperateType.Add ? '新建' : '编辑') + '角色';
        if (this.operateType === OperateType.Edit) {
            this.pageLoading = true;
            this.getRoleDetail().then((roleDetail) => {
                this.roleDetail = roleDetail;
                this.form.name = roleDetail.name;
                this.form.description = roleDetail.description;
            }).finally(() => {
                this.isLoaded = true;
                this.pageLoading = false;
            });
        }
        else {
            this.isLoaded = true;
            this.pageLoading = false;
        }
    }
};
__decorate([
    Ref()
], AddRole.prototype, "permSet", void 0);
__decorate([
    Ref()
], AddRole.prototype, "cform", void 0);
__decorate([
    Prop()
], AddRole.prototype, "roleId", void 0);
__decorate([
    Prop()
], AddRole.prototype, "value", void 0);
__decorate([
    Prop({ default: OperateType.Add })
], AddRole.prototype, "operateType", void 0);
__decorate([
    Watch('form', { deep: true })
], AddRole.prototype, "onFormChange", null);
__decorate([
    Watch('value')
], AddRole.prototype, "onValueChange", null);
AddRole = __decorate([
    Component({
        name: 'AddRole',
        components: {
            CForm: Form,
            CFormItem: FormItem,
            PermSet: () => import('./perm-set'),
        },
    })
], AddRole);
export default AddRole;
